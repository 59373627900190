define("discourse/plugins/docker_manager/discourse/components/docker-manager/console", ["exports", "@ember/component", "@glimmer/component", "discourse-common/utils/decorators", "@ember/template-factory"], function (_exports, _component, _component2, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="console-logs"
    {{did-insert this.scrollToBottom}}
    {{did-update this.scrollToBottom @output}}
  >
    {{~@output~}}
  </div>
  */
  {
    "id": "E4gmFwOC",
    "block": "[[[11,0],[24,0,\"console-logs\"],[4,[38,0],[[30,0,[\"scrollToBottom\"]]],null],[4,[38,1],[[30,0,[\"scrollToBottom\"]],[30,1]],null],[12],[1,[30,1]],[13]],[\"@output\"],false,[\"did-insert\",\"did-update\"]]",
    "moduleName": "discourse/plugins/docker_manager/discourse/components/docker-manager/console.hbs",
    "isStrictMode": false
  });
  let Console = _exports.default = (_class = class Console extends _component2.default {
    scrollToBottom(element) {
      if (this.args.followOutput) {
        element.scrollTop = element.scrollHeight;
      }
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "scrollToBottom", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "scrollToBottom"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Console);
});