define("discourse/plugins/docker_manager/discourse/routes/update-processes", ["exports", "@ember/routing/route", "@ember/runloop", "discourse-common/lib/later", "discourse-common/utils/decorators", "discourse/plugins/docker_manager/discourse/models/process-list"], function (_exports, _route, _runloop, _later, _decorators, _processList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  const REFRESH_INTERVAL = 5000;
  let UpgradeProcesses = _exports.default = (_class = class UpgradeProcesses extends _route.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "processes", null);
      _defineProperty(this, "refreshTimer", null);
      _defineProperty(this, "autoRefresh", false);
    }
    model() {
      this.processes = new _processList.default();
      this.autoRefresh = true;
      this.refresh();
      return this.processes;
    }
    deactivate() {
      this.autoRefresh = false;
    }
    async refresh() {
      if (this.autoRefresh) {
        await this.processes.refresh();
        this.refreshTimer = (0, _later.default)(this.refresh, REFRESH_INTERVAL);
      } else {
        (0, _runloop.cancel)(this.refreshTimer);
      }
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "refresh", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "refresh"), _class.prototype)), _class);
});