define("discourse/plugins/docker_manager/discourse/controllers/update-show", ["exports", "@ember/controller", "@ember/object", "@ember/service", "discourse-common/lib/helpers", "I18n", "discourse/plugins/docker_manager/discourse/models/repo"], function (_exports, _controller, _object, _service, _helpers, _I18n, _repo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let UpgradeShow = _exports.default = (_class = class UpgradeShow extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "messageBus", _descriptor, this);
      _initializerDefineProperty(this, "dialog", _descriptor2, this);
      _initializerDefineProperty(this, "upgradeStore", _descriptor3, this);
    }
    get complete() {
      return this.upgradeStore.upgradeStatus === "complete";
    }
    get failed() {
      return this.upgradeStore.upgradeStatus === "failed";
    }
    get multiUpgrade() {
      return this.model.length > 1;
    }
    get title() {
      if (this.multiUpgrade) {
        return _I18n.default.t("admin.docker.update_everything");
      } else {
        return _I18n.default.t("admin.docker.update_repo", {
          name: this.model.name
        });
      }
    }
    get isUpToDate() {
      return (0, _helpers.makeArray)(this.model).every(repo => repo.upToDate);
    }
    get upgrading() {
      return (0, _helpers.makeArray)(this.model).some(repo => repo.upgrading);
    }
    start() {
      this.upgradeStore.reset();
      if (this.multiUpgrade) {
        for (const repo of this.model) {
          if (!repo.upToDate) {
            repo.upgrading = true;
          }
        }
        return _repo.default.upgradeAll();
      }
      if (this.model.upgrading) {
        return;
      }
      return this.model.startUpgrade();
    }
    resetUpgrade() {
      this.dialog.confirm({
        message: _I18n.default.t("admin.docker.reset_warning"),
        didConfirm: async () => {
          if (this.multiUpgrade) {
            try {
              await _repo.default.resetAll(this.model.filter(repo => !repo.upToDate));
            } finally {
              this.upgradeStore.reset();
              for (const repo of this.model) {
                repo.upgrading = false;
              }
            }
            return;
          }
          await this.model.resetUpgrade();
          this.upgradeStore.reset();
        }
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "messageBus", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "dialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "upgradeStore", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "start", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "start"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resetUpgrade", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "resetUpgrade"), _class.prototype)), _class);
});